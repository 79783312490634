import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/arrow.svg";

function OrdersandDispatch() {
  return (
    <div>
      <div className="OrderDataBlock border-radius-medium">
        <div className="HomeWidhthTitleBar poppins-12px-medium">
          <p>Orders and Dispatch</p>

          <Link to="/">View more</Link>
        </div>
        <div className="OrderWidthBlock py-2 poppins-11px-medium text-grey">
          <div className="OrderWidhthSingalBlock p-3 bg-none mx-3">
            <p className="">
              Total
              <br /> Orders
            </p>

            <h3 className="OrdersNumber mt-1 text-primary">
              {" "}
              300<span className="FontSizeTwintin">k</span>
            </h3>
            <div className="PlannedBlock">
              <div>
                <p className="">
                  <span className="text-primary-600">
                    250K
                    <br />
                  </span>{" "}
                  Planned
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={arrow} alt="arrow" className="img-fluid" />
                <span className="OrderParcentge"> 2%</span>
              </div>
            </div>
          </div>
          <div className="OrderWidhthSingalBlock p-3 mx-3">
            <p className="">
              Orders
              <br /> Dispatched
            </p>

            <h3 className="OrdersNumber mt-1">
              {" "}
              150<span className="FontSizeTwintin">k</span>
            </h3>
            <div className="PlannedBlock">
              <div>
                <p className="">
                  <span className="text-primary-600">
                    100K
                    <br />
                  </span>{" "}
                  Planned
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={arrow} alt="arrow" className="img-fluid" />
                <span className="OrderParcentge"> 2%</span>
              </div>
            </div>
          </div>
          <div className="OrderWidhthSingalBlock p-3 mx-3">
            <p className="">
              Waiting
              <br />
              For Vehicle
            </p>

            <h3 className="OrdersNumber mt-1">
              {" "}
              50<span className="FontSizeTwintin">k</span>
            </h3>
          </div>
          <div className="OrderWidhthSingalBlock p-3 mx-3">
            <p className="">
              Orders
              <br /> Pending
            </p>

            <h3 className="OrdersNumber mt-1">
              {" "}
              50<span className="FontSizeTwintin">k</span>
            </h3>
          </div>
          <div className="OrderWidhthSingalBlock p-3 mx-3">
            <p className="">
              Orders
              <br /> Cancelled
            </p>

            <h3 className="OrdersNumber mt-1">
              {" "}
              50<span className="FontSizeTwintin">k</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdersandDispatch;
