import {CircularProgress, makeStyles} from "@material-ui/core";
import React from "react";



function DynamicCirculerProgress(props) {

    let {
        progressColor,
        progressBg,
        progress,
        circleSize,
        thickness,
        progressLableColor,
        progressLableSize,
        progressLableFontSize,
        progressLableBg,
        fill = false
    } = props;
    const useStylesFacebook = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: progressBg ? progressBg : "#C2FAEA",
        },
        top: {
            color: progressColor ? progressColor : '#00C28B',

            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
            fill: fill ? progressBg : "none"
        },
        ProgressLable: {
            background: progressLableBg ? progressLableBg : "#00C28B",
            color: progressLableColor ? progressLableColor : "white",
            width: progressLableSize ? progressLableSize : 0,
            height: progressLableSize ? progressLableSize : 0,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: progressLableFontSize ? progressLableFontSize : 20,
            fontWeight: 600
        },
        ProgressLableBlock: {
            position: "absolute",
            zIndex: 5,
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    }));
    const classes = useStylesFacebook();
    return (

        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={circleSize ? circleSize : 100}
                thickness={2.5}
                {...props}
                value={100}
            />
            <CircularProgress
                value={progress ? progress : 50}
                variant="determinate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={circleSize ? circleSize : 100}
                thickness={thickness ? thickness : 2}
                {...props}
            />
            <div className={classes.ProgressLableBlock}>
                <p className={classes.ProgressLable}>{progress ? progress : 50}%</p>
            </div>
        </div>

    );
}

export default DynamicCirculerProgress;
