import React, {forwardRef, useMemo} from "react";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  ListColor: {
    color: "#2C63E5",
    fontSize: 11,
    fontWeight: "500",
  },
  selected: {
    background: "#2C63E5 !important",
    padding: 15
  },
  activeText: {
    color: "#fff !important"
  }

}));

const ListItemLink = (props) => {
  const {icon, primary, to, active} = props;
  const classes = useStyles();
  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem selected={active} classes={{selected: classes.selected}} button component={renderLink}>
        <ListItemIcon>
          {icon ? <img width="20" alt="icon-img" src={icon} /> : null}
        </ListItemIcon>
        <ListItemText disableTypography={true} className={`${classes.ListColor} ${active && classes.activeText}`} primary={primary} />
      </ListItem>
    </li>
  );
}



export default ListItemLink