import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {IconButton} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import styles from '../../assets/css/styles';
import Snooze from '@material-ui/icons/Snooze';
import Share from '@material-ui/icons/Share';
import NotInterested from '@material-ui/icons/NotInterested';
const StyledMenu = withStyles({
    paper: {
        width: "117px",
        top: "148px !important",
        left: "1066px",

    }
})((props) => (
    <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: "#252525",
                minWidth: 0,
                fontSize: 10,
                marginRight: 10,
            },
        },

    },
}))(MenuItem);

const SubMenuBar = () => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick} edge="end" aria-label="delete">
                <MoreHorizIcon className={`${classes.MenuItemMoreBtn}`} />
            </IconButton>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem className={classes.SubMenuItem}>
                    <ListItemIcon>
                        <Snooze style={{fontSize: 18}} />
                    </ListItemIcon>
                    <ListItemText primary="Sent mail" />
                </StyledMenuItem>
                <StyledMenuItem className={classes.SubMenuItem}>
                    <ListItemIcon>
                        <NotInterested style={{fontSize: 18}} />
                    </ListItemIcon>
                    <ListItemText primary="Drafts" />
                </StyledMenuItem>
                <StyledMenuItem className={classes.SubMenuItem}>
                    <ListItemIcon>
                        <Share style={{fontSize: 18}} />
                    </ListItemIcon>
                    <ListItemText primary="Inbox" />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}

export default SubMenuBar