import "bootstrap/dist/css/bootstrap.min.css";
import {Route, Switch} from "react-router-dom";
import "./App.css";
import NotFoundPage from "./component/ErrorPages/NotFoundPage";


import Login from "./pages/Login";
import ProtectedRoute from "./Routers/Protected.route";

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Login} />
        <ProtectedRoute exact path="/dashboard" />
        <ProtectedRoute exact path="/my-alerts" />
        <ProtectedRoute exact path="/my-tasks" />
        <ProtectedRoute exact path="/components" />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}

export default App;
