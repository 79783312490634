import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {CFade} from "@coreui/react";
import routes from "../routes";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginTop: 40,
    background: "#F5F8FF",
    minHeight: "100vh"
  },
}));


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = (props) => {
  const classes = useStyles();
  return (
    <main className={`${classes.content} MainContent`}>
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )}
                />
              )
            );
          })}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Suspense>
    </main>
  );
};

export default React.memo(TheContent);
