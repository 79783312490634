import {faAngleLeft, faAngleRight, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {Button, Container} from 'react-bootstrap'
import "../assets/css/MyAlerts.css"
import AlertsTable from '../component/MyAlertsPage/AlertsTable'
import SnoozeIcon from '@material-ui/icons/Snooze';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ShareIcon from '@material-ui/icons/Share';
import Breadcrumbs from '../component/Breadcrumbs'



const MyAlerts = () => {
    return (
        <>
            <Breadcrumbs pageName={"Alerts"} siteName={false} />
            <Container fluid style={{paddingTop: "5em"}}>
                <div className="main-table-container">
                    <div className="table-header-block">
                        <div><p className="tableHeading">All Alerts</p></div>
                        <div className="mx-2">
                            <Button className="status-btn " variant="primary">All Alerts
                                <div className="notification-count outline-primary active-btn-count">14</div>
                            </Button>
                            <Button className="status-btn text-alert-500" style={{borderColor: "#FF5757"}} variant="outline-danger">Critical
                                <div className="notification-count text-alert-500 outline-danger">14</div>
                            </Button>
                            <Button className="status-btn text-warning-600" style={{borderColor: "#D97F30"}} variant="outline-warning">High
                                <div className="notification-count outline-warning text-warning-600">14</div>
                            </Button>
                            <Button className="status-btn text-primary-600" style={{borderColor: "#264CA4"}} variant="outline-primary">Medium
                                <div className="notification-count outline-primary text-primary-600">14</div>
                            </Button>
                            <Button className="status-btn text-success-500" style={{borderColor: "#00C28B"}} variant="outline-success">Low
                                <div className="notification-count outline-success text-success-500">14</div>
                            </Button>
                        </div>
                    </div>
                    <div className="table-header-block justify-content-between">
                        <div className="search-box">
                            <FontAwesomeIcon className="mx-2" icon={faSearch} /><input className="border-0" type="text" placeholder="Search" />
                        </div>
                        <div className="icon-corner">
                            <ShareIcon style={{color: "#0d6efd69"}} /><SnoozeIcon className="mx-3" style={{color: "#0d6efd69"}} />
                            <NotInterestedIcon style={{color: "#dc354570"}} />
                        </div>
                    </div>
                    <div className="main-table-block">
                        <AlertsTable />
                    </div>
                    <div className="table-header-block justify-content-between">
                        <div className="d-flex align-items-center">
                            <p>showing 10 of 12 records</p>
                            <p className="count-row-perpage">Rows per page:</p>
                            <select name="rows" id="table-row-select">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faAngleLeft} className="mx-3" size="lg" color="#2C63E5" />
                            <div>
                                <p className="page-num active">1</p></div><p className="page-num">2</p>
                            <FontAwesomeIcon icon={faAngleRight} className="mx-3" size="lg" color="#2C63E5" />
                        </div>
                    </div>

                </div>
            </Container>
        </>
    )
}

export default MyAlerts
