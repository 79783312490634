import {faPlus, faSlidersH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {makeStyles} from "@material-ui/core";
import React from "react";
import "../assets/css/header.css";


const useStyles = makeStyles((theme) => ({
    BreadcrumbShift: {
        zIndex: 500,
        right: 0,
        left: 0,
        position: "fixed",
        marginLeft: 220,

        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const Breadcrumbs = (props) => {

    let {pageName, siteName = true} = props;
    const classes = useStyles();
    return (
        <div className={`logo_container ${classes.BreadcrumbShift}`}>
            <div className="pro_contantsty">
                <div>
                    {siteName && <p className="m-0 breadcum-page-sub-heading source-sans-pro-reg">Control Tower</p>}
                    <h2 className="m-0 breadcum-page-heading">{pageName}</h2>
                </div>

                <div className="btn_container pt-2">
                    <div type="button" className="m-2">
                        <div className="filter_sty">
                            <FontAwesomeIcon icon={faSlidersH} className="roted_icon" />
                            <span> Filter</span>
                        </div>
                    </div>
                    <div type="button" className="m-2">
                        <div className="add_btn_sty">
                            <FontAwesomeIcon icon={faPlus} className="" />
                            <span> Add task</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Breadcrumbs
