import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Form, Table } from 'react-bootstrap'
import { AlertsData } from '../../utils/Alertdata'

function AlertsTable() {


    const CheckPriority = (priority) => {
        switch (priority) {
            case "critical":
                return <p className="text-alert-500">{priority}</p>
            case "high":
                return <p className="text-warning-600">{priority}</p>
            case "medium":
                return <p className="text-primary-500">{priority}</p>
            case "low":
                return <p className="text-success-500">{priority}</p>
            default:
                return <p className="text-alert-500">{priority}</p>
        }
    }

    return (
        <div>
            <Table className="DataTable" bordered >
                <thead>
                    <tr>
                        <th> <Form.Check type="checkbox" /></th>
                        <th>Alert</th>
                        <th>Priority</th>
                        <th><span className="mx-1">Timestamp</span><FontAwesomeIcon color="#2C63E5" icon={faLongArrowAltDown} /><FontAwesomeIcon color="#2C63E5" icon={faLongArrowAltUp} /></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        AlertsData.map((item, index) => (
                            <tr key={index.toString()}>
                                <td> <Form.Check type="checkbox" /></td>
                                <td>{item.alertreason}</td>
                                <td>{CheckPriority(item.priority)}</td>
                                <td>{item.timestamp}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>
        </div>
    )
}

export default AlertsTable
