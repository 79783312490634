import React from 'react'
import {Link} from 'react-router-dom'
import DynamicCirculerProgress from '../CirculerProgressSliders/DynamicCirculerProgress'


function NetworkandPlanning() {
    return (
        <>
            <div className="TransportationWidhthBlock border-radius-medium">
                <div className="HomeWidhthTitleBar poppins-12px-medium">
                    <p>Network and Planning</p>
                    <Link to="/">View more</Link>
                </div>
                <div className="PlannigBoxContainer pt-3">
                    <div className="PlannigSingaleBox border-radius-medium">

                        <p className="FontOverAllBox text-grey">Utilization</p>
                        <div className="SingaleBoxCircle pt-3">
                            <DynamicCirculerProgress
                                progressColor={"#2C63E5"}
                                progressBg={"#F5F8FF"}
                                progress={75}
                                circleSize={85}
                                thickness={2.4}
                                progressLableColor={"#1A1A1A"}
                                progressLableFontSize={20}
                                progressLableBg={"#F5F8FF"}
                                fill={true}
                            />
                        </div>

                        <div className="btnOtifOntimeContanet pt-4">
                            <div>
                                <button className="OtifBtn">Warehouse</button>
                            </div>
                            <div>
                                <button className="OntimeBtn text-grey">Plant</button>
                            </div>
                        </div>
                    </div>

                    <div className="PlannigSingaleBox border-radius-medium">
                        <p className="FontOverAllBox">Lead</p>
                        <div className="water-flow-chart">
                            <h3 className="OrdersNumber ">300</h3><p style={{fontSize: 10}}>K</p>
                        </div>

                        <div className="btnOtifOntimeContanet pt-4">
                            <div>
                                <button className="OtifBtn">Primary</button>
                            </div>
                            <div>
                                <button className="OntimeBtn">Secondary</button>
                            </div>
                        </div>
                    </div>
                    <div className="PlannigSingaleBox border-radius-medium">
                        <p className="FontOverAllBox">Mode Mix</p>

                        <div className="SingaleBoxCircle pt-3">
                            <DynamicCirculerProgress
                                progressColor={"#2C63E5"}
                                progressBg={"#F5F8FF"}
                                progress={75}
                                circleSize={85}
                                thickness={2.4}
                                progressLableColor={"#1A1A1A"}
                                progressLableFontSize={20}
                                progressLableBg={"#F5F8FF"}
                                fill={true}
                            />
                        </div>

                        <div className="btnOtifOntimeContanet pt-4">
                            <div>
                                <button className="OtifBtn">Road</button>
                            </div>
                            <div>
                                <button className="OntimeBtn">Rail</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default NetworkandPlanning
