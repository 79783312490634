export const AlertsData = [
    {
        alertreason: "This is a single line alert. Alerts more than 1 line will be wrapped in a tooltip and leaders at line end",
        priority: "critical",
        timestamp: "11:00 AM 21 Jun 2021"
    },
    {
        alertreason: "This is a single line alert. Alerts more than 1 line will be wrapped in a tooltip and leaders at line end",
        priority: "high",
        timestamp: "11:00 AM 21 Jun 2021"
    },
    {
        alertreason: "This is a single line alert. Alerts more than 1 line will be wrapped in a tooltip and leaders at line end",
        priority: "critical",
        timestamp: "11:00 AM 21 Jun 2021"
    },
    {
        alertreason: "This is a single line alert. Alerts more than 1 line will be wrapped in a tooltip and leaders at line end",
        priority: "critical",
        timestamp: "11:00 AM 21 Jun 2021"
    },
    {
        alertreason: "This is a single line alert. Alerts more than 1 line will be wrapped in a tooltip and leaders at line end",
        priority: "medium",
        timestamp: "11:00 AM 21 Jun 2021"
    },
    {
        alertreason: "This is a single line alert. Alerts more than 1 line will be wrapped in a tooltip and leaders at line end",
        priority: "low",
        timestamp: "11:00 AM 21 Jun 2021"
    },
]