import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Avatar, Badge, } from '@material-ui/core';

import MenuBar from '../component/HeaderMenus/MenuBar'


const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({

  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  colorError: {
    background: "#f8d7da",
    color: "#721c24"
  },
  colorSecondary: {
    background: "#fff3cd",
    color: "#f9a825"
  },
  avtarMedium: {
    width: "34px",
    height: "34px",
    fontSize: 14,
    color: "#000",
    fontWeight: "bolder",
    border: "1px solid #7a9dec",
    background: "#f5f8ff",
    textTransform: 'uppercase'
  },
  sectionDesktop: {
    paddingRight: 20
  }
}));

const Theheader = () => {
  const classes = useStyles();

  const [open] = React.useState(false);

  return (


    <AppBar
      elevation={0}
      color="white"
      position="fixed"
      className={clsx({
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar variant="dense">

        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <MenuBar />

          <IconButton className={"px-2"} aria-label="show 17 new notifications" color="inherit">
            <Badge classes={{ colorError: classes.colorError }} badgeContent={4} color={"error"}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton>
            <Avatar className={classes.avtarMedium}>sa</Avatar>
          </IconButton>
        </div>

      </Toolbar>
    </AppBar>


  );
}

export default Theheader