import React from 'react'
import CheckIcon from '@material-ui/icons/Check';

function TaskComplete() {
    return (
        <>
            <div className="addtask-success-popup">
                <p className="source-san-12px-reg"><CheckIcon style={{ fontSize: "15px", marginRight: "5px" }} />Added task successfully!</p>
            </div>
        </>
    )
}
export default TaskComplete