import { Container } from "react-bootstrap"

import VechicleLocation from "../../assets/Images/vechicleallocation.svg";
import Indent from "../../assets/Images/indentmanagement.svg";
import InplantTrack from "../../assets/Images/inplanttracking.svg";
import TraceAndTrack from "../../assets/Images/trackandtrace.svg";
import ControlTower from "../../assets/Images/manwithheadphone.svg";
import Epod from "../../assets/Images/EPOD.svg";

const LeftSideBlock = () => {
    return (

        <div className="container-left">
            <Container>
                <div className={"ImageContainer"}>
                    {/* <img className="img-fluid" src={Intivision} alt="intivision" /> */}
                </div>
                <div style={{ position: 'relative', margin: "0 6.5em" }}>
                    <div className="instruction-block">
                        <div className="inst-method">
                            <img className="img-fluid img-rounded" src={VechicleLocation} alt="" />
                            <p className="instruction-paragraph ">Vehicle Alllocation<br />& Route Planning</p>
                        </div>
                        <div className="inst-method">
                            <img className="img-fluid img-rounded" src={Indent} alt="" /><p className="instruction-paragraph ">Indent<br />Management</p>
                        </div>
                        <div className="inst-method">
                            <img className="img-fluid img-rounded" src={InplantTrack} alt="" /><p className="instruction-paragraph ">Inplant<br />Tracking</p>
                        </div>
                        <div className="inst-method">
                            <img className="img-fluid img-rounded" src={TraceAndTrack} alt="" /><p className="instruction-paragraph ">Track &<br />Trace</p>
                        </div>
                        <div className="inst-method">
                            <img className="img-fluid img-rounded" src={ControlTower} alt="" /><p className="instruction-paragraph ">Control<br />Tower</p>
                        </div>
                        <div className="inst-method">
                            <img className="img-fluid img-rounded" src={Epod} alt="" /><p className="instruction-paragraph ">E-POD</p>
                        </div>
                    </div>
                    <div className="dotted-border"></div>
                </div>

            </Container>
        </div>


    )
}

export default LeftSideBlock
