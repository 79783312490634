import { CFooter } from "@coreui/react";
import React from "react";

export default function TheFooter() {
  return (
    <CFooter className="FixedBlock d-none">
      <div className={"TheFooter"}>
        <span className="ml-1">© 2021 Flipr Innovation Labs</span>
        <span className="ml-1">Designed by: Gourav Jat</span>
      </div>
    </CFooter>
  );
}
