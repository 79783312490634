import React from 'react'
import { Link } from 'react-router-dom'

function Inventory() {
    return (
        <>
            <div className="OrderDataBlock border-radius-medium">
                <div className="HomeWidhthTitleBar poppins-12px-medium">
                    <p fontSizeTotalOrder>Inventory</p>

                    <Link to="/" fontSizeTotalOrder>
                        View more
                    </Link>
                </div>
                <div className="OrderWidthBlock py-3">
                    <div className="OrderWidhthSingalBlock p-1 bg-none">
                        <p className="fontSizeTotalOrder m-2 text-grey">
                            Total
                            <br /> Inventory
                        </p>

                        <h5 className="OrdersNumber pt-2 m-2">
                            {" "}
                            30<span className="fontSizeTotalOrder">Lakh</span>
                        </h5>
                    </div>
                    <div className="OrderWidhthSingalBlock p-1">
                        <p className="fontSizeTotalOrder m-2 text-grey">
                            Average
                            <br /> Inventory Days
                        </p>

                        <h5 className="OrdersNumber pt-2 m-2">
                            {" "}
                            3<span className="fontSizeTotalOrder"></span>
                        </h5>
                    </div>
                    <div className="OrderWidhthSingalBlock p-1">
                        <p className="fontSizeTotalOrder m-1 text-grey">
                            Stock out
                            <br />
                            Inventory
                        </p>
                        <div className="StockOut ">
                            <div className="p-2">
                                <h5 className="numberRed">3</h5>
                                <p className="fontSizeTotalOrder text-grey">Current</p>
                            </div>
                            <div className="p-2">
                                <h5 className="numbergreen">5</h5>
                                <p className="fontSizeTotalOrder text-grey">Predicted</p>
                            </div>
                        </div>
                    </div>
                    <div className="OrderWidhthSingalBlock p-1">
                        <p className="fontSizeTotalOrder m-1 text-grey">
                            Safety Stock
                            <br />
                            Breaches
                        </p>
                        <div className="StockOut ">
                            <div className="p-2">
                                <h5 className="numberRed">3</h5>
                                <p className="fontSizeTotalOrder text-grey">Current</p>
                            </div>
                            <div className="p-2">
                                <h5 className="numbergreen">5</h5>
                                <p className="fontSizeTotalOrder text-grey">Predicted</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Inventory
