import React from 'react'
import DynamicCirculerProgress from '../CirculerProgressSliders/DynamicCirculerProgress';


const CustomerSatisfaction = () => {
    return (
        <>
            <div className="CustomerSatisfection p-3 border-radius-medium">
                <div className="HomeWidhthTitleBar poppins-11px-medium">
                    <p>Customer Satisfaction</p>
                </div>
                <div className="CircleContaint">
                    <DynamicCirculerProgress
                        progressLableSize={65}
                        progress={85}
                        circleSize={140}
                        thickness={2.3}
                    />
                </div>
            </div>
        </>
    )
}

export default CustomerSatisfaction
