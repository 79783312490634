import React from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ErrorIcon from '@material-ui/icons/Error';
import {Badge, List, ListItem, ListItemText, } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom'
import styles from '../../assets/css/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SubMenuBar from './SubMenuBar';

export default function MenuListComposition() {
    const classes = styles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (

        <>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={"px-2"} aria-label="show 17 new notifications" color="inherit">
                <Badge classes={{colorSecondary: classes.colorSecondary}} badgeContent={4} color={"secondary"}>
                    <ErrorIcon />
                </Badge>
            </IconButton>


            <Popper className={classes.AlertMenu} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>

                                <List dense autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <ListItem to="/my-alerts" component={Link} onClick={handleClose}>
                                        <div className={classes.menuHeader}>
                                            <p style={{color: "#1A1A1A"}} className={classes.menuHeaderText}>Alerts</p>
                                            <Link className={classes.menuHeaderText} to="/my-alerts">View All</Link>
                                        </div>
                                    </ListItem>
                                    <div className={classes.menuBreaker}>
                                        Recent
                                    </div>
                                    <ListItem className={classes.MenuItemContent} button onClick={handleClose}>
                                        <FiberManualRecordIcon className={`${classes.MenuItemStatusCircle} ${classes.Danger}`} />
                                        <ListItemText disableTypography={true} className={`${classes.MenuItemText} source-sans-pro-reg`} primary={"A 2 line stockout alert. This shows the extended height"} />
                                        <ListItemSecondaryAction >
                                            {/* <IconButton edge="end" aria-label="delete">
                                                <MoreHorizIcon className={`${classes.MenuItemMoreBtn}`} />
                                            </IconButton> */}
                                            <SubMenuBar />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem className={classes.MenuItemContent} button onClick={handleClose}>
                                        <FiberManualRecordIcon className={`${classes.MenuItemStatusCircle} ${classes.Warning}`} />
                                        <ListItemText disableTypography={true} className={`${classes.MenuItemText} source-sans-pro-reg`} primary={"1 line stockout alert"} />
                                        <ListItemSecondaryAction >
                                            <IconButton edge="end" aria-label="delete">
                                                <MoreHorizIcon className={`${classes.MenuItemMoreBtn}`} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem className={classes.MenuItemContent} button onClick={handleClose}>
                                        <FiberManualRecordIcon className={`${classes.MenuItemStatusCircle} ${classes.Warning}`} />
                                        <ListItemText disableTypography={true} className={`${classes.MenuItemText} source-sans-pro-reg`} primary={"A 2 line stockout alert. This shows the extended height"} />
                                        <ListItemSecondaryAction >
                                            <IconButton edge="end" aria-label="delete">
                                                <MoreHorizIcon className={`${classes.MenuItemMoreBtn}`} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <div className={classes.menuBreaker}>
                                        Earlier
                                    </div>
                                    <ListItem className={classes.MenuItemContent} button onClick={handleClose}>
                                        <FiberManualRecordIcon className={`${classes.MenuItemStatusCircle} ${classes.Warning}`} />
                                        <ListItemText disableTypography={true} className={`${classes.MenuItemText} source-sans-pro-reg`} primary={"1 line stockout alert"} />
                                        <ListItemSecondaryAction >
                                            <IconButton edge="end" aria-label="delete">
                                                <MoreHorizIcon className={`${classes.MenuItemMoreBtn}`} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>

                                    <ListItem className={classes.MenuItemContent} button onClick={handleClose}>
                                        <FiberManualRecordIcon className={`${classes.MenuItemStatusCircle} ${classes.Warning}`} />
                                        <ListItemText disableTypography={true} className={`${classes.MenuItemText} source-sans-pro-reg`} primary={"1 line stockout alert"} />
                                        <ListItemSecondaryAction >
                                            <IconButton edge="end" aria-label="delete">
                                                <MoreHorizIcon className={`${classes.MenuItemMoreBtn}`} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>

                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>

    );
}