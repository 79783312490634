import React from "react";
import TheContent from "./TheContent";
import TheFooter from "./TheFooter";
import Theheader from "./Theheader";
import TheSidebar from "./TheSidebar";
import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

}));
export default function TheLayout(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <TheSidebar {...props} />
        <Theheader {...props} />
        <TheContent {...props} />
        <TheFooter {...props} />
      </div>
    </>
  );
}
