import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import LinearDeterminate from './LinearDeterminate';


function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionRight(props) {
    return <LinearDeterminate />;
}

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

const SnakeBar = () => {
    const [open, setOpen] = React.useState(false);
    const [transition, setTransition] = React.useState(undefined);

    const handleClick = (Transition) => () => {
        setTransition(() => Transition);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClick(TransitionLeft)}>Right</Button>
            <Button onClick={handleClick(TransitionUp)}>Up</Button>
            <Button onClick={handleClick(TransitionRight)}>Left</Button>
            <Button onClick={handleClick(TransitionDown)}>Down</Button>
            <Snackbar
                open={open}
                onClose={handleClose}
                TransitionComponent={transition}
                message="I love snacks"
                key={transition ? transition.name : ''}
            />
        </div>
    );
}

export default SnakeBar