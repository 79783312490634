import React from 'react'


import {Link} from "react-router-dom";
import DynamicCirculerProgress from '../CirculerProgressSliders/DynamicCirculerProgress';

function Transportation() {
    return (
        <>
            <div className="TransportationWidhthBlock border-radius-medium">
                <div className="HomeWidhthTitleBar poppins-12px-medium">
                    <p>Transportation</p>

                    <Link to="/">View more</Link>
                </div>
                <div className="pt-3">
                    <p className="fontSizeTotalOrder text-start text-grey">Service Levels</p>
                </div>
                <div className="TransportCircleContainer">
                    <DynamicCirculerProgress
                        progressColor={"#2C63E5"}
                        progressBg={"#F5F8FF"}
                        progress={75}
                        circleSize={90}
                        thickness={2.4}
                        progressLableColor={"#1A1A1A"}

                        progressLableFontSize={20}
                        progressLableBg={"#F5F8FF"}
                        fill={true}
                    />
                </div>
                <div className="btnOtifOntimeContanet pt-4">
                    <div>
                        <button className="OtifBtn">OTIF</button>
                    </div>
                    <div>
                        <button className="OntimeBtn">On time</button>
                    </div>
                </div>
                <div className="averageInvestryContainer">
                    <div className="AverageBox">
                        <div className="">
                            <p className="fontSizeTotalOrder text-grey">
                                Average
                                <br /> Kilometres Run
                            </p>

                            <h5 className="OrdersNumber pt-2">
                                1500<span className="fontSizeTotalOrder">kms</span>
                            </h5>
                        </div>
                    </div>
                    <div className="investryBox">
                        <div className="">
                            <p className="fontSizeTotalOrder text-grey">
                                Inventory
                                <br /> In Transit
                            </p>

                            <h5 className="OrdersNumber pt-2">
                                {" "}
                                1500<span className="fontSizeTotalOrder">MT</span>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="HrsBoxContaint pt-4">
                    <div className="HrsFirst">
                        <p className="HrsTextFont">
                            3<span className="spanHrsFont">hrs</span>
                        </p>
                    </div>
                    <div className="HrsSecond">
                        <p className="HrsTextFont">
                            5<span className="spanHrsFont">hrs</span>
                        </p>
                    </div>
                </div>
                <div className="HrsText text-grey">
                    <div className="fontSizeTotalOrder">Plant TAT</div>
                    <div className="fontSizeTotalOrder">Journey TAT</div>
                </div>
                <div className="ThreeCircleContainer pt-5 text-grey">
                    <div className="d-flex flex-column align-items-center">
                        <p className="treeeCircleTeaxtSize m-1">Fleet Utilization</p>
                        <DynamicCirculerProgress
                            progressColor={"#2C63E5"}
                            progressBg={"#F5F8FF"}
                            progress={75}
                            circleSize={85}
                            thickness={2.4}
                            progressLableColor={"#1A1A1A"}
                            progressLableFontSize={20}
                            progressLableBg={"#F5F8FF"}
                            fill={true}
                        />
                    </div>
                    <div className="d-flex flex-column align-items-center mx-3">
                        <p className="treeeCircleTeaxtSize m-1">EPOD Received</p>
                        <DynamicCirculerProgress
                            progressColor={"#2C63E5"}
                            progressBg={"#F5F8FF"}
                            progress={75}
                            circleSize={85}
                            thickness={2.4}
                            progressLableColor={"#1A1A1A"}
                            progressLableFontSize={20}
                            progressLableBg={"#F5F8FF"}
                            fill={true}
                        />
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <p className="treeeCircleTeaxtSize m-1">Indents Accepted</p>
                        <DynamicCirculerProgress
                            progressColor={"#2C63E5"}
                            progressBg={"#F5F8FF"}
                            progress={75}
                            circleSize={85}
                            thickness={2.4}
                            progressLableColor={"#1A1A1A"}
                            progressLableFontSize={20}
                            progressLableBg={"#F5F8FF"}
                            fill={true}
                        />
                    </div>
                </div>

            </div>

        </>
    )
}

export default Transportation
