import HomeActive from "../assets/Images/homePrimary.png"
import Home from "../assets/Images/homeWhite.png"
import MyTasksActive from "../assets/Images/myTaskprimary.png"
import MyTasks from "../assets/Images/myTaskwhite.png"
import BuildBlockActive from "../assets/Images/builingPrimary.png"
import BuildBlock from "../assets/Images/builingWhite.png"
export const _nav = [
  {
    _tag: "MenuItem",
    name: "Cockpit",
    to: "/dashboard",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "My Tasks",
    to: "/my-tasks",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },
  {
    _tag: "MenuItem",
    name: "Inventory Management",
    to: "/not-found",
    icon: BuildBlock,
    activeIcon: BuildBlockActive,
  }
];
