import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "../../assets/css/homecontaint.css";

import OrdersandDispatch from "./OrdersandDispatch";
import CustomerSatisfaction from "./CustomerSatisfaction";
import Transportation from "./Transportation";
import Inventory from "./Inventory";
import CostsandFinance from "./CostsandFinance";
import NetworkandPlanning from "./NetworkandPlanning";
function DashboardInner() {
  return (
    <div className="home_contant_container">
      <Container fluid>
        <Row className="my-3">
          <Col lg="10">
            <OrdersandDispatch />
          </Col>
          <Col lg="2">
            <CustomerSatisfaction />
          </Col>

          <Col lg="5" className="mt-3">
            <Transportation />
          </Col>
          <Col lg="7" className="mt-3">
            <Inventory />
            <CostsandFinance />
          </Col>
        </Row>
        <NetworkandPlanning />
      </Container>
    </div>
  );
}

export default DashboardInner;
