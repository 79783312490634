import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

import '../assets/css/login.css'
import Logo from "../assets/Images/intugine-logo@2x.png";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HeadWithMic from "../assets/Images/HWM.PNG";
import LeftSideBlock from '../component/LoginPage/LeftSideBlock';
import { Link } from 'react-router-dom';



const Login = () => {
    const [showeye, setshoweye] = useState(false)
    return (
        <>
            <div className="loginpage">
                <Container fluid>
                    <Row>
                        <Col lg="7">
                            <LeftSideBlock />
                        </Col>
                        <Col lg="5" className="p-0" >
                            <div className="login-form-block">
                                <div className="form-block">
                                    <img width="60px" height="60px" className="img-fluid  my-2" src={Logo} alt="" />
                                    <h1 className="poppins-32px-700 text-primary-500">Intugine Technologies</h1>
                                    <p className="poppins-16px-600 text-grey">Logistics Through Innovation</p>
                                    <Button className="small-btn-light poppins-11px-medium">IntuVision</Button>

                                    <Form.Group className="CustomFormGroup" controlId="formBasicEmail">
                                        <Form.Label className="form-lable-text">Username</Form.Label>
                                        <div className="input-password">
                                            <input className="forminput border-0" type="text" placeholder="Enter Username here" /></div>
                                    </Form.Group>

                                    <Form.Group className="CustomFormGroup" controlId="formBasicPassword">
                                        <Form.Label className="form-lable-text">Password</Form.Label>
                                        <div className="input-password">
                                            <input className="forminput border-0" type={showeye ? "text" : "password"} placeholder="Enter Password here" />
                                            <FontAwesomeIcon onClick={() => setshoweye(!showeye)} className="text-right mt-1 text-primary" icon={showeye ? faEye : faEyeSlash} /></div>
                                    </Form.Group>
                                    <Link to="/dashboard" className="forminput login-sub-btn">
                                        Submit
                                    </Link>


                                </div>
                                <div className="form-footer">
                                    <div className="p-2">
                                        <div className="d-flex justify-content-end align-items-center">
                                            <img src={HeadWithMic} alt="" style={{ width: "15px", height: "15px" }} />
                                            <p className="mx-1 poppins-11px-medium text-primary" style={{ fontSize: "10px", margin: "0" }}>Support</p>
                                        </div>
                                        <p style={{ fontSize: "10px", textAlign: "right" }}>reachus@intugine.com</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Login
