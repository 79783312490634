import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import fullLogo2x from '../assets/Images/fullLogo2x.png'
import SortIcon from '@material-ui/icons/Sort';
import {blue} from '@material-ui/core/colors';
import {_nav} from '../Containers/_nav';
import ListItemLink from '../component/Sidebar/ListItemLink';
import "../assets/css/sidebar.css";
import {Button} from '@material-ui/core';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({

  drawer: {
    width: drawerWidth,
    flexShrink: 0,


  },
  drawerOpen: {
    boxShadow: "0px 24px 40px #1a1a1a29",
    border: "none",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    boxShadow: "0px 24px 40px #1a1a1a29",
    border: "none",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 55,
  },
  toolbarHeader: {
    minHeight: "45px !important",
    display: 'flex',
    alignItems: 'center',

  },
  logo: {
    width: 150
  }

}));

const TheSideBar = (props) => {


  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const ToggleDrawer = () => {
    setOpen(!open);
  };
  let currentPage = props.location.pathname;
  return (
    < >
      <Drawer

        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{

          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbarHeader}>
          <IconButton style={{paddingRight: 0, paddingLeft: 20}} onClick={ToggleDrawer}>
            {open ? <ChevronLeftIcon style={{color: blue[600]}} /> : <SortIcon style={{color: blue[600]}} />}
          </IconButton>

          {open && <img className={`img-fluid ${classes.logo}`} src={fullLogo2x} alt="full-logo" />}
        </div>
        <List>

          {_nav.map((sidebarmenus, index) => {
            if (sidebarmenus._tag === "MenuItem") {
              return (
                <>
                  <ListItemLink
                    active={sidebarmenus.to === currentPage ? true : false}
                    icon={sidebarmenus.to === currentPage ? sidebarmenus.icon : sidebarmenus.activeIcon}
                    key={index.toString()}
                    to={`${sidebarmenus.to}`}
                    primary={sidebarmenus.name}
                  />

                </>
              );
            } else {
              return <div key={index.toString()} />;
            }

          })}


        </List>
        {
          open &&
          <div className="SidebarContentBlock">
            <div className="BlockContents">
              <h4 className="poppins-16px-600">Learn more about our services</h4>
              <p className="source-sans-pro-reg">We offer a bunch of products to help improve your logistics supply chain.</p>
              <Button variant="outlined" color="primary">
                Learn More
              </Button>
            </div>
          </div>
        }

      </Drawer>
    </>
  );
}

export default TheSideBar