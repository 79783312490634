import React from 'react'
import { Link } from 'react-router-dom'

function CostsandFinance() {
    return (
        <>
            <div className="OrderDataBlock mt-2 border-radius-medium">
                <div className="HomeWidhthTitleBar poppins-12px-medium">
                    <p className="fontSizeTotalOrder">Costs and Finance</p>

                    <Link to="/" fontSizeTotalOrder>
                        View more
                    </Link>
                </div>
                <div className="LogisticCostContainer mt-4">
                    <p className="fontSizeTotalOrder p-2">Total Logistics Cost</p>

                    <div className=" d-flex justify-content-around">
                        <div className="OverAllBox ">
                            <div className="m-2">
                                <p className="BorderBottom text-grey">Overall Cost</p>
                                <div className="d-flex">
                                    <div className="OverAllBoxSecond ">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox text-grey">Actual</p>
                                    </div>
                                    <div className="OverAllBoxSecond">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox text-grey">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox text-grey">Plan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="OverAllBox">
                            <div className="m-2">
                                <p className="BorderBottom text-grey">Primary Cost</p>
                                <div className="d-flex">
                                    <div className="OverAllBoxSecond">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox text-grey">Plan</p>
                                    </div>
                                    <div className="OverAllBoxSecond">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox">Plan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex justify-content-around ">
                        <div className="OverAllBox">
                            <div className="m-2">
                                <p className="BorderBottom text-grey">Handling Cost</p>
                                <div className="d-flex">
                                    <div className="OverAllBoxSecond">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox text-grey">Plan</p>
                                    </div>
                                    <div className="OverAllBoxSecond">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox text-grey">Plan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="OverAllBox">
                            <div className="m-2">
                                <p className="BorderBottom text-grey">Secondary Cost</p>
                                <div className="d-flex">
                                    <div className="OverAllBoxSecond">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox text-grey">Plan</p>
                                    </div>
                                    <div className="OverAllBoxSecond">
                                        <h6 className="TextWeighth">
                                            ₹30<span className="FontOverAllBox">Lakh</span>
                                        </h6>
                                        <p className="FontOverAllBox text-grey">Plan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="AvergeCustomerBoxContainer">
                    <div className="AvgCustomerFirst m-2">
                        <p className="FontOverAllBox">
                            Average Customer
                            <br /> Outstanding Days
                        </p>
                        <h6 className="pt-2 TextWeighth">21</h6>
                    </div>
                    <div className="AvgCustomersecond m-2">
                        <p className="FontOverAllBox">
                            Freight Bills
                            <br /> Pending
                        </p>
                        <h6 className="pt-2 TextWeighth">
                            500<span className="FontOverAllBox">k</span>
                        </h6>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CostsandFinance
