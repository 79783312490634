import Home from "./pages/Home";
import MyAlerts from "./pages/MyAlerts";
import MyTask from "./pages/MyTask";
import Components from "./component/MyTaskPage/Adding-Task-Progress-Bar/Components";


const routes = [
  {path: "/components", name: "Adding Task Progress Bar ", component: Components},
  {path: "/dashboard", name: "Home", component: Home, },
  {path: "/my-alerts", name: "My Alerts", component: MyAlerts},
  {path: "/my-tasks", name: "My Alerts", component: MyTask},

];
export default routes;
