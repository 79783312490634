import { Button } from 'bootstrap';
import React from 'react'
import { Modal } from 'react-bootstrap';

function DeleteModal(props) {
    let { show, handleClose } = props;
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <p className="poppins-20px-semibold delete-modal-header">Delete Task</p>
                    <p className="delete-modal-paragraph">All the associated data with task name long will be erased. Do you want to delete the task?</p>
                    <div className="delete-modal-btn">
                        <Button className="poppins-11px-medium m-2 modal-btn-cancel" variant="light" onClick={handleClose}>
                            No, Cancel
                        </Button>
                        <Button className="poppins-11px-medium m-2 modal-btn-delete" variant="danger" onClick={handleClose}>
                            Yes, delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default DeleteModal
