export const TaskData = [
    {

        taskreason: "Task name short",
        description: "Description can be 1 line, tool tip for 2",
        priority: "Medium",
        deadline: "11:00 AM 21 Jun 2021",
        Completed: "11:00 AM 21 Jun 2021",
        status: "completed"
    },
    {

        taskreason: "Task name short",
        description: "Description can be 1 line, tool tip for 2",
        priority: "Medium",
        deadline: "11:00 AM 21 Jun 2021",
        Completed: "11:00 AM 21 Jun 2021",
        status: "completed"
    },
    {

        taskreason: "Task name short",
        description: "Description can be 1 line, tool tip for 2",
        priority: "Medium",
        deadline: "11:00 AM 21 Jun 2021",
        Completed: "11:00 AM 21 Jun 2021",
        status: "completed"
    },
    {

        taskreason: "Task name short",
        description: "Description can be 1 line, tool tip for 2",
        priority: "Medium",
        deadline: "11:00 AM 21 Jun 2021",
        Completed: "11:00 AM 21 Jun 2021",
        status: "pending"
    },
    {

        taskreason: "Task name short",
        description: "Description can be 1 line, tool tip for 2",
        priority: "Medium",
        deadline: "11:00 AM 21 Jun 2021",
        Completed: "11:00 AM 21 Jun 2021",
        status: "in progress"
    },
    {

        taskreason: "Task name short",
        description: "Description can be 1 line, tool tip for 2",
        priority: "Medium",
        deadline: "11:00 AM 21 Jun 2021",
        Completed: "11:00 AM 21 Jun 2021",
        status: "completed"
    },
]