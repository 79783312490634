
import React from "react";
import DashboardInner from "../component/homePage/DashboardInner";
import Breadcrumbs from '../component/Breadcrumbs'
import {Container} from 'react-bootstrap'

function Home() {
  return (
    <>
      <Breadcrumbs pageName={"Cockpit"} />
      <Container fluid style={{paddingTop: "5em"}}>
        <div className="home_sty">
          <div className="py-3">
            <DashboardInner />
          </div>
        </div>
      </Container>

    </>
  );
}

export default Home;
