import React from 'react'

import "../../../assets/css/Components.css"
import LinearDeterminate from './LinearDeterminate';
import SnakeBar from './SnakeBar';
import TaskComplete from './TaskComplete';

function TaskAddSuccessful() {
    return (
        <><br />
            <SnakeBar />
            <br />
            <LinearDeterminate />
            <br />
            <TaskComplete />
        </>
    )
}

export default TaskAddSuccessful
