import React from 'react'
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Table } from 'react-bootstrap'
import { TaskData } from '../../utils/TaskData'
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';


function TaskTable(props) {
    const CheckStatus = (status) => {
        switch (status) {
            case "completed":
                return <p className="text-success-500"><CheckIcon style={{ fontSize: "15px", marginRight: "5px" }} />{status}</p>
            case "in progress":
                return <p className="text-primary"><PlayArrowIcon style={{ fontSize: "15px", marginRight: "5px" }} />{status}</p>
            case "pending":
                return <p className="text-warning"><ErrorOutlineIcon style={{ fontSize: "15px", marginRight: "5px" }} />{status}</p>
            default:
                return <p className="text-danger">{status}</p>
        }
    }
    return (
        <div>
            <Table className="DataTable" bordered >
                <thead>
                    <tr>
                        <th> <Form.Check onClick={() => { props.toggleClick() }} checked={props.selectedRow} type="checkbox" /></th>
                        <th>Task</th>
                        <th>Description</th>
                        <th>Priority</th>
                        <th><span className="mx-1">Deadline</span><FontAwesomeIcon color="#2C63E5" icon={faLongArrowAltDown} /><FontAwesomeIcon color="#2C63E5" icon={faLongArrowAltUp} /></th>
                        <th><span className="mx-1">Completed on</span><FontAwesomeIcon color="#2C63E5" icon={faLongArrowAltDown} /><FontAwesomeIcon color="#2C63E5" icon={faLongArrowAltUp} /></th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        TaskData.map((item, index) => (
                            <tr key={index.toString()}>
                                <td> <Form.Check onClick={() => { props.toggleClick() }} checked={props.selectedRow} type="checkbox" /></td>
                                <td>{item.taskreason}</td>
                                <td>{(item.description)}</td>
                                <td>{item.priority}</td>
                                <td>{item.deadline}</td>
                                <td>{item.Completed}</td>
                                <td>{CheckStatus(item.status)}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>
        </div >

    )
}

export default TaskTable
