import React, {useState} from 'react'
import {faAngleLeft, faAngleRight, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Button, Container} from 'react-bootstrap'
import "../assets/css/MyAlerts.css"
import "../assets/css/MyTask.css";
import TaskTable from '../component/MyTaskPage/TaskTable'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import Breadcrumbs from '../component/Breadcrumbs'
import ActionsMenuBtn from '../component/MyTaskPage/ActionsMenuBtn'
import DeleteModal from '../component/Modals/DeleteModal'


function MyTask() {
    const [RowSelected, setRowSelected] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const toggleClick = () => {
        setRowSelected(!RowSelected)
    }
    return (
        <>
            <Breadcrumbs pageName={"My Tasks"} />
            <Container fluid style={{paddingTop: "5em"}}>
                <div className="main-table-container">
                    <div className="table-header-block">
                        <div><p className={"tableHeading"}>All Tasks</p></div>
                        <div className="mx-2">
                            <Button className="status-btn " variant="primary">All Tasks
                                <div className="notification-count outline-primary active-btn-count">12</div>
                            </Button>
                            <Button className="status-btn text-success-500" variant="outline-success">Complete
                                <div className="notification-count outline-success text-success-500">8</div>
                            </Button>
                            <Button className="status-btn text-primary-500" variant="outline-primary">In progress
                                <div className="notification-count outline-primary text-primary-500">2</div>
                            </Button>
                            <Button className="status-btn text-warning-600" variant="outline-warning">Pending
                                <div className="notification-count outline-warning text-warning-600">2</div>
                            </Button>
                        </div>
                    </div>
                    <div className="table-header-block justify-content-between">
                        <div className="search-box">
                            <FontAwesomeIcon className="mx-2" icon={faSearch} /><input className="border-0" type="text" placeholder="Search" />
                        </div>
                        <div className="icon-corner">
                            <div style={{borderRadius: "5px !important"}}>
                                <ActionsMenuBtn RowSelected={RowSelected}
                                />
                            </div>
                            <EditIcon className={`mx-3 ${RowSelected ? `text-primary-500` : `text-primary-200`}`} style={{borderBottom: `2px solid ${RowSelected ? `#2c63e5` : `#bfd2fd`}`}} color={RowSelected ? "#2C63E5" : "#0d6efd69"} />
                            <button className="border-0 bg-none" onClick={() => handleShow()}> <DeleteOutlineIcon className={`${RowSelected ? `text-alert-500` : `text-alert-200`}`} /></button>
                            <DeleteModal show={show} handleClose={handleClose} />
                        </div>
                    </div>
                    <div className="main-table-block">
                        <TaskTable selectedRow={RowSelected} toggleClick={toggleClick} />
                    </div>
                    <div className="table-header-block justify-content-between">
                        <div className="d-flex align-items-center">
                            <p>showing 10 of 12 records</p>
                            <p className="count-row-perpage">Rows per page:</p>
                            <select name="rows" id="table-row-select">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faAngleLeft} className="mx-3" size="lg" color="#2C63E5" />
                            <div>
                                <p className="page-num active">1</p></div><p className="page-num">2</p>
                            <FontAwesomeIcon icon={faAngleRight} className="mx-3" size="lg" color="#2C63E5" />
                        </div>
                    </div>

                </div>
            </Container>
        </>
    )
}

export default MyTask
