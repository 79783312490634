import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const StyledMenu = withStyles({

})((props) => (
    <Menu
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {

    },
}))(MenuItem);

const ActionsMenuBtn = (props) => {
    let {RowSelected} = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>

            <div
                type="button"
                onClick={RowSelected && handleClick}
                className={`my-task-action-btn background-grey-200 ${RowSelected && `enabled`}`}>
                <button className={`border-0 background-grey-200 action-btn-btn ${RowSelected && `enabled`}`}>{RowSelected ? `Selected` : `Actions`}</button>
                <button className={`border-0 background-grey-200 action-btn-icon ${RowSelected && `enabled`}`}><FontAwesomeIcon icon={faAngleDown} /></button>
            </div>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                    <p className="text-success-500 poppins-12px-medium"><CheckIcon style={{fontSize: "15px", marginRight: "5px"}} />Completed</p>
                </StyledMenuItem>
                <StyledMenuItem>
                    <p className="text-primary poppins-12px-medium"><PlayArrowIcon style={{fontSize: "15px", marginRight: "5px"}} />In progress</p>
                </StyledMenuItem>
                <StyledMenuItem>
                    <p className="text-warning poppins-12px-medium"><ErrorOutlineIcon style={{fontSize: "15px", marginRight: "5px"}} />Pending</p>
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}

export default ActionsMenuBtn